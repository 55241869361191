<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
        Om oss
      </column>
      <column
        xs="12"
        md="8">
        <div class="article-body clearfix mb-3">
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'aboutus-page',
  extends: Page
};
</script>
